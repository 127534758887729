import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooExpressPlusImg from 'images/blog-images/shipkoo-express-plus.png';
import EcommerceFulfillmentImg from 'images/blog-images/ecommerce-fulfillment.png';
import FbaOrOverseasFeatured from 'images/featured-image/fba-or-overseas-featured.png';
import FulfillmentAmazonImg from 'images/blog-images/fulfillment-by-amazon.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query FbaOrOverseasWarehouseImg {
      file(relativePath: { eq: "fulfillment-by-amazon.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="fba or overseas warehouse"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="FBA or Overseas Warehouse? Which One is Your Best Choice? | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-fba-or-overseas" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "FBA or Overseas Warehouse? Which One is Your Best Choice?",
              content: intl.formatMessage({ id: "blog-post-meta-title-fba-or-overseas" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FbaOrOverseasFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="fba-or-overseas-warehouse-which-one-is-your-best-choice"
        title="FBA or Overseas Warehouse? Which One is Your Best Choice?"
        date="2020-09-24"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              FBA, referred to as Fulfillment By Amazon, is shipped by Amazon. Sellers need to first send their products to Amazon's pre-allocated warehouses. After they are placed in the warehouse, each time a customer places an order, Amazon uses its powerful sorting function to select products and package them for delivery to customers. The process of sending from the seller's warehouse to the Amazon warehouse is called the first process, which is the responsibility of the seller. The process by which a customer places an order and Amazon arranges the products to the customer is the real Fulfilled By Amazon. The responsibilities that occurred during this logistics period are borne by Amazon, that is to say, all accidents that occur during this logistics process, including delayed arrivals, unpacking, natural disasters and other unexpected accidents are all the responsibility of Amazon. Amazon will arrange customer service to deal with after-sales. 
            </p>

            <Image src={FulfillmentAmazonImg} className="" alt="fulfillment by amazon"/>

            <h5>
              <strong>
                Advantages of using FBA:
              </strong>
            </h5>

            <OrderedList>
              <li>
                Customers receive the goods quickly, and the choice of delivery methods is flexible and changeable. Customers can choose the delivery speed that arrives within one week, two days and one day
              </li>
              <li>
                Amazon automatically helps buyers solve problems caused by logistics problems
              </li>
              <li>
                Consumers are more willing to choose FBA products than other shipping methods
              </li>
              <li>
                Direct contact with 30% of Amazon Prime users in American households who have a higher willingness to spend
              </li>
              <li>
                Enjoy special recommended care from Amazon for FBA products. (For example: help grab the shopping cart, improve the listing ranking, help become a featured seller, enjoy Amazon's recommendation function, etc.)
              </li>
              <li>
                Enjoy the 24-hour service provided by Amazon to improve the quality of customer service
              </li>
              <li>
                Customers can enjoy free shipping for purchasing products over $49
              </li>
            </OrderedList>

            <h5>
              <strong>
                Risks and troubles for using FBA:
              </strong>
            </h5>

            <OrderedList>
              <li>
                In general, FBA's shipping costs are relatively high, which leads to correspondingly higher product prices
              </li>
              <li>
                Poor flexibility without multi-language support, only communicating with customers in English, and cannot handle problems in time
              </li>
              <li>
                The FBA warehouse needs to ensure that the products in the warehouse can be sold, and the operator needs a detailed inventory plan, otherwise a large amount of storage fees will be charged
              </li>
              <li>
                FBA has clear and strict regulations on product storage
              </li>
              <li>
                The return address only supports the United States
              </li>
              <li>
                FBA customers return the goods at will, causing unnecessary losses and troubles to the seller
              </li>
            </OrderedList>

            <p>
              Overseas warehouses are referred to as <a href="/services/ecommerce-fulfillment/">eCommerce fulfillment</a> services in Shipkoo. It is the warehousing, sorting, packaging and delivery of goods provided by online foreign trade trading platforms and logistics service providers independently or jointly for sellers at the sales destination. The seller stores the goods in the local warehouse. When the buyer has a demand, the overseas warehouse service provider responds quickly, sorting, packaging and delivering the goods in time. For small sellers, third-party overseas warehouses are undoubtedly a wise choice to save costs.
            </p>

            <Image src={EcommerceFulfillmentImg} className="" alt="ecommerce fulfillment"/>

            <h5>
              <strong>
                Advantages of using Overseas warehouses:
              </strong>
            </h5>

            <OrderedList>
              <li>
                The logistics cost is very low, the delivery to the customer is fast, and the customer experience is good. Overseas warehouses are equivalent to delivering products from domestic warehouses to domestic customers, which is time-effective
              </li>
              <li>
                Significantly increase the profit margin of products
              </li>
              <li>
                Order fulfillment is more convenient, orders and delivery are synchronized, automatic batch processing of orders can be realized, and a free ERM warehouse management platform can be used
              </li>
              <li>
                Automatic and efficient order fulfillment procedures; better warehouse management
              </li>
              <li>
                Large-sized products or special attributes could be handled in Overseas warehouses
              </li>
            </OrderedList>

            <h5>
              <strong>
                Risks and troubles for using Overseas warehouses:
              </strong>
            </h5>

            <OrderedList>
              <li>
                Delivery through overseas warehouses will not enjoy Amazon's special recommendation and introduction relative to FBA
              </li>
              <li>
                Limited orders lead to less profit, so the cost of doing overseas warehouses will be relatively high
              </li>
            </OrderedList>

            <Image src={ShipkooExpressPlusImg} className="" alt="shipkoo express plus"/>

            <p>
              In Shipkoo, we have both <a href="/services/ecommerce-fulfillment/">eCommerce Fulfillment</a> and <a href="/services/fba-prep-and-forwarding/">FBA Prep & Forwarding</a>. We help eCommerce sellers to pick, pack and dispatch orders from any of our global fulfillment centers. Moreover, we have helped thousands of online sellers to manage inventory flows to Amazon. <a href="/contact/">Contact our shipping specialists</a> who will give you suggestions and design your most suitable shipping solution.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;